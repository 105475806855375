<template>
  <b-container fluid class="px-0">
    <b-row>
      <b-col cols="12">
        <div class="auth-container d-flex align-items-stretch">
          <!--jimir3x-->
          <div class="auth-left position-relative auth-left-extra  ">
            <!--jimir3x-->
            <div class="auth-left-header zoom ">
              <a href="#">
                <img src="/img/logo-white.svg" width="190" height="30" alt />
              </a>
            </div>
            <div class="auth-left-top-img zoom">
              <div class="auth-left-top-img-content">
                <img src="/img/login-bg-1.png" alt="" />
              </div>
            </div>
            <div class="auth-left-bottom-left-img zoom">
              <div class="auth-left-bottom-left-img-content position-relative">
                <img src="/img/login-bg-2.png" alt="" />
              </div>
            </div>
            <div class="auth-left-bottom-right-img zoom">
              <div class="auth-left-bottom-left-img-content position-relative">
                <img src="/img/login-bg-3.png" alt="" />
              </div>
            </div>
            <div class="auth-left-center">
              <h3 class="auth-title text-white text-center">
                {{ $t('auth.general.title') }}
              </h3>
              <p class="auth-left-center-desc text-center">
                {{ $t('auth.general.desc') }}
              </p>
            </div>
          </div>
          <router-view />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'auth-layout',
};
</script>
